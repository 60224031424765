exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-share-tsx": () => import("./../../../src/pages/blog/{markdownRemark.frontmatter__slug}/share.tsx" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-share-tsx" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/blog/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-templates-post-tsx-content-file-path-workspace-src-content-post-7-new-job-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/workspace/src/content/post-7-new-job.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-workspace-src-content-post-7-new-job-mdx" */)
}

